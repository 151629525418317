<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as LEAD_ORG_TYPES } from './store'
import { ROUTES as LEAD_ORG_ROUTES } from './router'

import OrganizationListMixin from '../../../components/organization/mixins/OrganizationListMixin'
import LeadOrganizationTable from './LeadOrganizationTable'
import LeadOrganizationCommonFilters from './LeadOrganizationCommonFilters'

export default {
  name: 'LeadOrganizationList',
  mixins: [OrganizationListMixin],
  components: {
    'component-table': LeadOrganizationTable,
    'common-filters': LeadOrganizationCommonFilters
  },
  data () {
    return {
      LEAD_ORG_ROUTES,
      title: this.$t('Extra Organizations'),
      rolePerm: ['organization_customerorganization_list'],
      actionEnablePermission: ['organization_customerorganization_enable'],
      actionDisablePermission: ['organization_customerorganization_disable'],
      orgType: this.$route.params.orgType
    }
  },
  computed: {
    ...mapGetters({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS
    }),
    showList: function () {
      if (this.$route.name === this.LEAD_ORG_ROUTES.LEAD_ORGANIZATION_LIST) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS
    }),
    openEditForm (id) {
      this.$router.push({ name: this.LEAD_ORG_ROUTES.LEAD_ORGANIZATION_CREATE })
    },
    openDetailForm (id) {
      this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: id } })
    },
    getComponentList () {
      if (this.orgType) {
        return this.setFilters({ filter__organization_types__code: this.orgType })
      } else {
        return this.setFilters({})
      }
    }
  }
}
</script>
