<template>
  <div class="sidebar-filters">
    <b-button ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-times" /> {{ 'RESET ALL FILTERS' | translate }}
    </b-button>
    <status-list-filter
      @edit="editComponentFilters($event, 'filter__status__in')"
      @clean="cleanComponentFilters(['filter__status__in'])"
    />
    <organization-group-list-filter
      @edit="editComponentFilters($event, 'filter__organization_groups__in')"
      @clean="cleanComponentFilters(['filter__organization_groups__in'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as LEAD_ORG_TYPES } from './store'
import CommonFiltersMixin from '../../../components/organization/mixins/OrganizationCommonFiltersMixin'

export default {
  name: 'LeadOrganizationCommonFilters',
  mixins: [CommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS
    })
  }
}
</script>
