<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as LEAD_ORG_TYPES } from './store'
import { ROUTES as LEAD_ORG_ROUTES } from './router'

import OrganizationTableMixin from '../../../components/organization/mixins/OrganizationTableMixin'
import { ORGANIZATION_CUSTOMER_LEAD_CODE } from '../../../../../const'

export default {
  name: 'LeadOrganizationTable',
  mixins: [OrganizationTableMixin],
  data () {
    return {
      showDocumentsButton: true,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: true
    }
  },
  computed: {
    ...mapGetters({
      selectAll: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.selectAll,
      selectedList: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.selectedList
    })
  },
  methods: {
    ...mapMutations({
      setList: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setList,
      setSelectedList:
        LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS
    }),
    getDocumentsRouter (id, name) {
      return {
        name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DOCUMENTS,
        params: { orgId: id, orgName: name, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE }
      }
    },
    getInspectionRouter (id, name) {
      return {
        name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_INSPECTIONS_LIST,
        params: { id: id, orgName: name, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE }
      }
    }
  }
}
</script>
